import axios from "axios";
import MarkdownPreview from "@uiw/react-markdown-preview";
import React, { useMemo, useEffect, useState } from "react";
import { Spin, Button, Card, List } from "antd";

import { ArrowUpOutlined } from "@ant-design/icons";

export const readApiThreads = (
  user_value_cookie,
  setLoading,
  setThreads,
  setActiveThread,
  setNewChat,
  activeThread
) => {
  setLoading(true);
  setNewChat(0);
  // console.log(user_value_cookie);
  const apiUrl = "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
  const requestData = {
    operation: "read",
    username: user_value_cookie,
  };

  axios
    .post(apiUrl, requestData)
    .then((response) => {
      const fetchedChats = response.data?.body?.chats || {};
      const threadsArray = Object.keys(fetchedChats).map((key) => ({
        id: key,
        name: fetchedChats[key].chat_name,
        messages: Object.values(fetchedChats[key].messages),
      }));

      threadsArray.forEach((thread) => {
        const uniqueMessages = {};
        thread.messages.forEach((message) => {
          const messageId = message.message_id;
          if (
            !uniqueMessages[messageId] ||
            new Date(uniqueMessages[messageId].response.question_time) <
              new Date(message.response.question_time)
          ) {
            uniqueMessages[messageId] = message;
          }
        });
        thread.messages = Object.values(uniqueMessages);
      });

      threadsArray.sort((a, b) => {
        const lastMsgA =
          a.messages.length > 0
            ? new Date(a.messages[a.messages.length - 1].response.question_time)
            : new Date(0);
        const lastMsgB =
          b.messages.length > 0
            ? new Date(b.messages[b.messages.length - 1].response.question_time)
            : new Date(0);
        return lastMsgB - lastMsgA;
      });

      setThreads(threadsArray);
      if (threadsArray.length > 0 && !activeThread) {
        setActiveThread(threadsArray[0]);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      setLoading(false);
    });
};

export const writeApi = async (
  user_value_cookie,
  setLoading,
  setSkeletonLoading,
  setSkeletonLLMLoading,
  setNewChat,
  newMessage,
  activeThread,
  setActiveThread
) => {
  setLoading(true);
  setSkeletonLoading(true);
  setNewChat(0);

  const newMessageContent = {
    message_id: `m${new Date().getTime()}`,
    message: newMessage,
    type: "question",
    response: {
      llm_response: "",
      question_time: new Date().toISOString(),
      table_data: "",
    },
  };

  let updatedMessages;
  if (activeThread?.messages) {
    updatedMessages = [...activeThread.messages, newMessageContent];
  } else {
    updatedMessages = [newMessageContent];
  }

  setActiveThread({ ...activeThread, messages: updatedMessages });

  try {
    const max_tokens_llm = 100;
    const max_tokens_sql = 50;
    const max_tokens_questions = 150;
    const max_tokens_agent = 4;

    // Call API Gateway to get the agent name
    console.log(newMessage);
    const AgentNameData = await fetchAgentNameUpdatedAdy(
      newMessage,
      max_tokens_agent
    );
    const agentName = AgentNameData.agent_name?.Router?.toLowerCase();

    console.log(agentName);

    // Determine the model name based on agent's response

    const model_name = agentName?.toLowerCase().includes("sql")
      ? "SQLDocker"
      : "MistralDocker";

    const promises = [];
    promises.push(
      fetchRelatedQuestions(newMessage, "MistralDocker", max_tokens_questions)
    );

    if (model_name === "SQLDocker") {
      promises.push(
        fetchSummary(newMessage, model_name, max_tokens_llm, max_tokens_sql)
      );
    }

    if (agentName === "companyanalysis") {
      promises.push(
        fetchSummaryCompany(
          newMessage,
          agentName,
          max_tokens_llm,
          max_tokens_sql,
          AgentNameData
        )
      );
    } else {
      promises.push(Promise.resolve({ llm_response: "", table_data: "" }));
    }

    const results = await Promise.all(promises);
    // console.log(results);

    const relevantQuestionsData = results[0];
    // console.log(relevantQuestionsData);
    const llmResponseData = results[1] || { llm_response: "", table_data: "" };

    console.log(llmResponseData);

    // Update response object
    newMessageContent.response.llm_response = llmResponseData.llm_response;
    newMessageContent.response.router = AgentNameData.agent_name?.Router;
    newMessageContent.response.subrouter = AgentNameData.agent_name?.SubRouter;
    newMessageContent.response.entity = AgentNameData.agent_name?.Entity;
    newMessageContent.response.metric = AgentNameData.agent_name?.Metric;

    newMessageContent.response.relevant_questions =
      relevantQuestionsData?.related_questions;
    let symbols;
    try {
      symbols = llmResponseData?.table_data?.startsWith("Symbol@@@")
        ? llmResponseData?.table_data?.substring(10)
        : "";
      // console.log(symbols);

      newMessageContent.response.table_data = symbols;
    } catch (error) {
      newMessageContent.response.table_data = "";
    }

    updatedMessages = updatedMessages.map((msg) =>
      msg.message_id === newMessageContent.message_id ? newMessageContent : msg
    );
    setActiveThread({ ...activeThread, messages: updatedMessages });

    console.log(llmResponseData);

    if (llmResponseData?.table_data?.startsWith("Symbol@@@")) {
      const symbols = llmResponseData?.table_data?.substring(10);
      const extracted_columns = llmResponseData?.extracted_columns;

      handleMistralResponseSQLData(
        user_value_cookie,
        llmResponseData,
        newMessage,
        max_tokens_llm,
        max_tokens_sql,
        newMessageContent,
        activeThread,
        setActiveThread,
        setSkeletonLLMLoading,
        updatedMessages,
        symbols,
        extracted_columns
      );
    }

    setSkeletonLoading(false);

    let side_chat_name;
    let requestData;
    if (activeThread.messages.length === 0) {
      side_chat_name = await SideChatSummary(newMessage, 8);
      requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThread.id,
        chat_name: side_chat_name?.side_chat_name || activeThread.name,
        messages: [newMessageContent],
      };
    } else {
      requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThread.id,
        messages: [newMessageContent],
      };
    }

    // Write to API
    // console.log(newMessageContent);
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";

    console.log(requestData);

    await axios.post(apiUrl, requestData);

    if (llmResponseData?.table_data?.startsWith("Symbol@@@")) {
      await axios.post(apiUrl, requestData);
    }
  } catch (error) {
    console.error("Error:", error);
    setLoading(false);
    setSkeletonLoading(false);
  } finally {
    setLoading(false);
  }
};

const handleMistralResponseSQLData = async (
  user_value_cookie,
  llmResponseData,
  newMessage,
  max_tokens_llm,
  max_tokens_sql,
  newMessageContent,
  activeThread,
  setActiveThread,
  setSkeletonLLMLoading,
  updatedMessages,
  symbols,
  extracted_columns
) => {
  try {
    let max_tokens_llm = 500;
    setSkeletonLLMLoading(true);

    newMessageContent.response.llm_response = "";

    newMessageContent.response.table_data = symbols;
    newMessageContent.response.extracted_columns = extracted_columns;

    updatedMessages = updatedMessages.map((msg) =>
      msg.message_id === newMessageContent.message_id ? newMessageContent : msg
    );
    setActiveThread({ ...activeThread, messages: updatedMessages });

    // Write to API
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
    const requestData = {
      operation: "write",
      username: user_value_cookie,
      chat_id: activeThread.id,
      chat_name: activeThread.name,
      messages: [newMessageContent],
    };

    // console.log(requestData);

    await axios.post(apiUrl, requestData);

    setSkeletonLLMLoading(false);
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchSummaryCompany = async (
  userInput,
  agent_name,
  max_tokens_llm,
  max_tokens_sql,
  AgentNameData
) => {
  let prompt;
  let max_tokens;

  max_tokens = max_tokens_sql;
  console.log(AgentNameData);
  // Call the API with model_name as "SQL" to fetch the SQL query
  // const sqlResponseData = await fetchSQLResponse(
  //   userInput,
  //   model_name,
  //   max_tokens
  // );

  // let sqlQuery = sqlResponseData.sql_query_llm;
  // // console.log(sqlQuery);
  // prompt = userInput + "@@@" + sqlQuery;
  // model_name = "SQLDataDocker";
  // // console.log(prompt);

  // try {
  //   const response = await fetch(
  //     "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify({
  //         text: prompt,
  //         model_name: model_name,
  //         max_tokens: max_tokens,
  //       }),
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error("Failed to fetch summary");
  //   }

  //   const data = await response.json();
  //   console.log(data);

  return {
    llm_response: "",
    table_data: "Symbol@@@ " + AgentNameData.agent_name.Entity[0],
    extracted_columns: "columns@@@" + AgentNameData.agent_name.Metric,
  };
};

const fetchSummary = async (
  userInput,
  model_name,
  max_tokens_llm,
  max_tokens_sql
) => {
  let prompt;
  let max_tokens;

  max_tokens = max_tokens_sql;
  // Call the API with model_name as "SQL" to fetch the SQL query
  const sqlResponseData = await fetchSQLResponse(
    userInput,
    model_name,
    max_tokens
  );

  let sqlQuery = sqlResponseData.sql_query_llm;
  // console.log(sqlQuery);
  prompt = userInput + "@@@" + sqlQuery;
  model_name = "SQLDataDocker";
  // console.log(prompt);

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    console.log(data);

    return {
      llm_response: data.summary,
      table_data: data.summary,
      extracted_columns: data.extracted_columns,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

const fetchRelatedQuestions = async (userInput, model_name, max_tokens) => {
  let prompt;

  prompt =
    "<s>[INST] Imagine you are an expert & award winning stock market analyst who specializes in asking the right questions relevant to a thorough stock research. Given a user query , generate 3 further precise, expert and relevant questions with focus on identifying similar companies related to the given query. I want exact 3 questions.  Your questions should be all-encompassing and can be answered independently. Don't use words like 'GIVEN QUERY' or 'GIVEN INDUSTRY'. If an industry or sector is provided, try to respond with diverse questions with similar sectors pertinent to financial markets. If a ratio like MarketCap or ROI is asked in the question, your response should have a slightly different ratio based on your stock market understanding. The questions should be brief and less than 20 words. QUESTION :" +
    userInput +
    ". Start with similar questions directly without any note or disclaimer.[/INST]";

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();

    return {
      related_questions: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const fetchSymbolsTable = async (userInput, max_tokens) => {
  prompt = userInput;

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: "extract_symbol_table_query",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      symbols_table: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const fetchAgentNameUpdatedAdy = async (userInput, max_tokens) => {
  prompt = userInput;
  console.log(prompt);

  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: prompt,
          model_name: "RouterResponse",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    console.log(data);

    return {
      agent_name: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

const fetchSQLResponse = async (userInput, model_name, max_tokens) => {
  // console.log(userInput, model_name, max_tokens);
  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: userInput,
          model_name: model_name,
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch SQL response");
    }

    const data = await response.json();
    console.log(data);

    return {
      sql_query_llm:
        data.summary
          ?.split("```sql")[0]
          ?.split("```")[0]
          ?.split(";")[0]
          ?.replace("\n", " ")
          ?.trim() + ";",
    };
  } catch (error) {
    console.error("Error fetching SQL response:", error.message);
    throw error;
  }
};

const SideChatSummary = async (userInput, max_tokens) => {
  try {
    const response = await fetch(
      "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: userInput,
          model_name: "SideChat",
          max_tokens: max_tokens,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const data = await response.json();
    // console.log(data);

    return {
      side_chat_name: data.summary,
    };
  } catch (error) {
    console.error("Error fetching summary:", error.message);
    throw error;
  }
};

export const StreamComponent = ({
  question,
  streamActive,
  setStreamActive,
  activeThread,
  user_value_cookie,
  AgentNameData,
  symbols_sql,
  symbols_table,
  setLlmResponseData,
  setRelatedQuestionShow,
  InterruptWrite,
  setInterruptWrite,
  RouterData,
}) => {
  const [messages, setMessages] = useState("");
  const [questionAsked, setquestionAsked] = useState("");
  const [questionAskedUpdated, setquestionAskedUpdated] = useState("");
  const [activeThreadUpdated, setActiveThreadUpdated] = useState();

  let symbols_sql_extracted = symbols_sql?.table_data;

  let extracted_columns_test = symbols_sql?.extracted_columns?.split("@@@")[1];

  useEffect(() => {
    // setIsLoading(true); // Start loading when the stream is active and question is set

    if (streamActive && question?.length > 0) {
      setLlmResponseData("SampleChat");
      setRelatedQuestionShow(false);
      setquestionAskedUpdated(question);
      setActiveThreadUpdated(activeThread);
      let answerType = AgentNameData;
      let url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
        question
      )}&answer_type=${encodeURIComponent(answerType)}`;
      if (AgentNameData == "SQL") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(
          answerType
        )}&symbols_sql_extracted=${encodeURIComponent(
          symbols_sql_extracted
        )}&extracted_columns=${encodeURIComponent(extracted_columns_test)}`;
      }

      if (AgentNameData == "CompanyAnalysis") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(
          answerType
        )}&subrouter=${encodeURIComponent(
          RouterData?.SubRouter
        )}&symbols_sql_extracted=${encodeURIComponent(RouterData?.Entity[0])}`;
      }

      if (AgentNameData == "OutOfScope") {
        url = `https://stockbuzz2.ngrok.app/stream?question=${encodeURIComponent(
          question
        )}&answer_type=${encodeURIComponent(answerType)}`;
      }

      //  console.log(url)

      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        const newMessage = event.data; // Replace <br /> with \n
        setMessages((prevMessages) => `${prevMessages}${newMessage}`);
      };

      eventSource.onerror = (error) => {
        setquestionAsked(question);
        // console.error("EventSource failed:", error);
        eventSource.close();
      };

      return () => {
        eventSource.close();

        // setStreamActive(false);
      };
    }
  }, [InterruptWrite]); // Reconnect based on question or streamActive change

  useEffect(() => {
    if (questionAsked != "" || messages?.trimStart() != "") {
      const newMessageContent = {
        message_id: `m${new Date().getTime()}`,
        message: questionAskedUpdated,
        type: "question",
        response: {
          llm_response: messages?.trimStart(),
          question_time: new Date().toISOString(),
        },
      };

      setLlmResponseData(messages?.trimStart());

      const apiUrl =
        "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
      const requestData = {
        operation: "write",
        username: user_value_cookie,
        chat_id: activeThreadUpdated.id,
        messages: [newMessageContent],
      };

      // console.log("Final requestData to send:", requestData);
      setRelatedQuestionShow(true);
      setStreamActive(false);

      // Sending the requestData to your API
      axios
        .post(apiUrl, requestData)
        .then((response) => {
          // console.log("Response from API:", response.data);
        })
        .catch((error) => {
          // console.error("Error sending data to API:", error);
        });
    }
  }, [questionAsked, InterruptWrite]); // Depend on streamActive and messages to send final data

  const sanitizedMessages = messages
    .replace(/<a[^>]+href="[^"]*"[^>]*>(.*?)<\/a>/g, "$1")
    ?.trimStart();

  return (
    <div className="markdown-preview">
      <MarkdownPreview source={sanitizedMessages} />
    </div>
  );
};

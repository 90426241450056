import React, { useState, useEffect, useRef, useCallback } from "react";
import Latex from "react-latex";
import "katex/dist/katex.min.css";
import RcResizeObserver from "rc-resize-observer";
import { DCFFCompany } from "./Valuation";
import {
  Layout,
  Menu,
  Card,
  Input,
  Button,
  message,
  Skeleton,
  Dropdown,
  Divider,
  List,
  Table,
  Tag,
  Spin,
  Collapse,
  Slider,
  Select,
} from "antd";
import Spreadsheet from "react-spreadsheet";
import { DataGrid } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import {
  LeftOutlined,
  RightOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import "./App.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Typography } from "antd";
import { Tiny } from "@ant-design/plots";
import { Area } from "@ant-design/plots";
import {
  ProCard,
  ProTable,
  StatisticCard,
  EditableProTable,
  ProFormField,
} from "@ant-design/pro-components";
import { Scatter } from "@ant-design/plots";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import {
  Column as BarChartPlot,
  Line as LineChartPlot,
} from "@ant-design/plots";

import { InputNumber } from "antd";
import "./App.css";

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;
const { Statistic } = StatisticCard;
const { Column } = Table;
const { Option } = Select;

export const DemoTinyColumnProfit = ({ data, color, metric, isMobile }) => {
  // Sort the data by calendarYear in ascending order
  // console.log(data)
  const sortedData = data?.slice().sort((a, b) => a.date.localeCompare(b.date));

  const config = {
    data: sortedData,
    xField: "calendarYear",
    yField: metric,
    // height: 100,
    // width: 220,
    autoFit: true,
    style: {
      fill: color,
      fillOpacity: 0.7,
    },
    tooltip: metric,
  };

  return (
    <div style={{ width: isMobile ? "100%" : "220px", height: "100px" }}>
      <Tiny.Column {...config} />
      {/* Display annual change percentage */}
    </div>
  );
};

export function AdyDemoTinyArea({ data, isMobile }) {
  // Sort the data by date in ascending order
  // console.log(data)
  const sortedData = data
    ?.slice()
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    ?.map((item, date) => ({ date: item.date, price: item.price }));

  // Calculate percentage change

  const firstPrice = sortedData[0]?.price;
  const lastPrice = sortedData[sortedData.length - 1]?.price;
  const percentageChange = ((lastPrice - firstPrice) / firstPrice) * 100;
  // console.log(percentageChange)

  // Determine color based on the sign of the percentage change
  const textColor = percentageChange >= 0 ? "green" : "red";

  // Format percentage change
  const formattedPercentageChange = isNaN(percentageChange)
    ? ""
    : percentageChange >= 0
    ? `+${Math.round(percentageChange)}%`
    : `${Math.round(percentageChange)}%`;

  const config = {
    data: sortedData,
    autoFit: true,
    height: isMobile ? 100 : 80, // Adjust height based on mobile or desktop

    padding: 8,
    xField: "date",
    yField: "price",
    smooth: false,
    tooltip: "price",
    style: {
      fill: "linear-gradient(-90deg, white 0%, darkgreen 100%)",
      fillOpacity: 0.1,
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Tiny.Line {...config} />
      {/* Display percentage change only when available */}
      {formattedPercentageChange && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "small",
            fontWeight: "normal",
            color: textColor,
          }}
        >
          {formattedPercentageChange}
        </div>
      )}
    </div>
  );
}

function Company_Tiny_Charts({
  input_symbols,
  metrics,
  chartDataPrice,
  chartData,
}) {
  return (
    <div>
      <h3 style={{ color: "#808080" }}>
        Charts(Key metrics) for Top 3 companies
      </h3>
      {input_symbols?.map((symbol) => (
        <ProCard
          key={symbol}
          title={symbol}
          bordered
          headerBordered
          style={{ marginBottom: "16px" }}
        >
          <Row gutter={[16, 16]}>
            {metrics.map((metric, index) => (
              <Col key={`${symbol}-${index}`} span={8}>
                <ProCard title={metric.title}>
                  {metric.metric === "price" ? (
                    <AdyDemoTinyArea
                      data={chartDataPrice?.filter(
                        (item) => item.symbol === symbol
                      )}
                    />
                  ) : (
                    <DemoTinyColumnProfit
                      data={chartData?.filter((item) => item.symbol === symbol)}
                      color={metric.color}
                      metric={metric.metric}
                    />
                  )}
                </ProCard>
              </Col>
            ))}
          </Row>
        </ProCard>
      ))}
    </div>
  );
}

const Container = styled.div`
  padding: 20px;
  background: #f9f9f9;
`;

const SlidersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const TableContainer = styled.div`
  margin-top: 20px;

  .custom-row {
    height: 12px; /* Adjust this value to change the row height */
  }

  .custom-cell {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const DCFResultsContainer = styled.div`
  margin-left: 20px;
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #1890ff;
  border-color: #1890ff;
  transition: all 0.3s;

  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }

  &:disabled {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  &:active {
    background-color: #096dd9;
    border-color: #096dd9;
    transform: scale(0.95);
  }
`;

const defaultData = new Array(20).fill(1).map((_, index) => ({
  id: (Date.now() + index).toString(),
  year: `202${index}`,
  revenue: "1000",
  ebit: "100",
  taxRate: 10,
  capex: "50",
  nwc: "20",
  depreciation: "30",
}));

export const NewsCarousel = ({ width, height, symbol }) => {
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);

  function truncateTitle(title, numWords) {
    const words = title.split(" ");
    if (words.length > numWords) {
      const truncatedWords = words.slice(0, numWords);
      return truncatedWords.join(" ") + "...";
    }
    return title;
  }

  useEffect(() => {
    const fetchNews = async () => {
      const input_symbols = Array.isArray(symbol) ? symbol.join(",") : symbol;
      try {
        const response = await axios.get(
          "https://financialmodelingprep.com/api/v3/stock_news?tickers=" +
            input_symbols +
            "&page=0&apikey=1fa68a2dab3f0428435613ea52e3677a"
        );

        // Deduplicate news based on URL
        const uniqueNews = [];
        const seenURLs = new Set();

        response.data.forEach((news) => {
          if (!seenURLs.has(news.url)) {
            uniqueNews.push(news);
            seenURLs.add(news.url);
          }
        });

        setCombinedData(uniqueNews.slice(0, 20));
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    setLoading(true);

    fetchNews();
  }, [symbol]);

  const swiperParams = {
    spaceBetween: 20,
    navigation: true,
    breakpoints: {
      576: {
        slidesPerView: 1.2, // Show 1 card per slide for screens with width >= 576px (small mobile devices)
      },
      992: {
        slidesPerView: 2.6, // Show 3 cards per slide for screens with width >= 992px (tablets and larger mobile devices)
      },
    },
  };

  return (
    <div style={{ width, height, marginTop: "2%", marginBottom: "2%" }}>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div style={{ marginBottom: "2%", height: 350 }}>
          <ProCard title="Latest News" bordered>
            <Swiper {...swiperParams} navigation={true} modules={[Navigation]}>
              {combinedData.map((card, index) => {
                const truncatedTitle = truncateTitle(card.title, 18); // Function to truncate the title

                return (
                  <SwiperSlide key={index}>
                    <ProCard
                      bordered
                      hoverable
                      style={{
                        height: "300px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          height: "150px",
                          overflow: "hidden",
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        <img
                          src={card.image}
                          alt={card.title}
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "8px 16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          flexGrow: 1,
                        }}
                      >
                        <a
                          href={card.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {truncatedTitle}
                          </p>
                        </a>
                      </div>
                    </ProCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </ProCard>
        </div>
      )}
    </div>
  );
};

const InfoTable = () => {
  const tableData = [
    { key: "1", name: "Current Price", value: "$300" },
    { key: "2", name: "Analyst Estimates", value: "$350" },
    { key: "3", name: "Percent Difference", value: "16.67%" },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "right",
    },
  ];

  return (
    <Card
      style={{
        height: "100%",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Stock Info</h3>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        showHeader={false}
        size="small"
        style={{ width: "100%" }}
      />
    </Card>
  );
};

const CompanyChartsSingle = ({ symbols }) => {
  const [chartData, setChartData] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [metric, setMetric] = useState("revenue");
  const [latestResults, setLatestResults] = useState({});
  const [previousResults, setPreviousResults] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              text: symbols,
              model_name: "ChartsDataIncome",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        let result = await response.json();
        if (result) {
          result = result?.summary;
          const formattedData = result
            ?.map((entry) => ({
              symbol: entry.symbol,
              date: entry.fillingDate,
              date_period: entry.period,
              calendarYear: entry.period + "`" + entry.calendarYear,
              revenue: entry.revenue / 1000000000,
              netIncome: entry.netIncome / 1000000000,
              eps: entry.eps,
              grossProfit: entry.grossProfit / 1000000000,
            }))
            ?.sort((a, b) => new Date(b.date) - new Date(a.date))
            ?.slice(0, 12)
            ?.sort((a, b) => new Date(a.date) - new Date(b.date));

          setChartData(formattedData);
        }

        const response_price = await fetch(
          "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              text: symbols,
              model_name: "ChartsDataPrice",
            }),
          }
        );

        if (!response_price.ok) {
          throw new Error("Failed to fetch data");
        }

        let result_price = await response_price.json();
        if (result_price) {
          result_price = result_price?.summary;
          const formattedPriceData = result_price
            ?.map((entry) => ({
              symbol: entry.symbol,
              date: entry.date,
              price: entry.close,
            }))
            ?.sort((a, b) => new Date(b.date) - new Date(a.date))
            ?.slice(0, 300)
            ?.sort((a, b) => new Date(a.date) - new Date(b.date));
          setChartDataPrice(formattedPriceData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (symbols) {
      fetchData();
    }
  }, [symbols]);

  useEffect(() => {
    const getLatestResults = () => {
      const latestEntry = chartData[chartData?.length - 1];
      const latestPriceEntry = chartDataPrice[chartDataPrice.length - 1];

      if (latestEntry && latestPriceEntry) {
        const { date_period, revenue, netIncome } = latestEntry;
        const previousEntry = chartData[chartData?.length - 2] || {};
        const previousRevenue = previousEntry?.revenue || revenue;
        const revenueChange =
          ((revenue - previousRevenue) / previousRevenue) * 100;
        return {
          date_period,
          revenueChange: revenueChange?.toFixed(2),
          revenue,
          netIncome,
          latestDate: latestEntry?.date,
        };
      }
      return {};
    };

    setLatestResults(getLatestResults());
  }, [chartData, chartDataPrice]);

  useEffect(() => {
    const getLatestResults = () => {
      const latestEntry = chartData[chartData?.length - 2];
      const latestPriceEntry = chartDataPrice[chartDataPrice.length - 1];

      if (latestEntry && latestPriceEntry) {
        const { date_period, revenue, netIncome } = latestEntry;
        const previousEntry = chartData[chartData?.length - 3] || {};
        const previousRevenue = previousEntry?.revenue || revenue;
        const revenueChange =
          ((revenue - previousRevenue) / previousRevenue) * 100;
        return {
          date_period,
          revenueChange: revenueChange?.toFixed(2),
          revenue,
          netIncome,
          latestDate: latestEntry?.date,
        };
      }
      return {};
    };

    setPreviousResults(getLatestResults());
  }, [chartData, chartDataPrice]);

  let latestDate = latestResults?.latestDate || "2024-03-01";
  let previousDate = previousResults?.latestDate || "2024-03-01";

  const barConfig = {
    data: chartData,
    xField: "calendarYear",
    yField: metric,
    height: 300,
    color: "#ffffff00",
    columnStyle: {
      stroke: "#69c0ff",
      lineWidth: 2,
      fillOpacity: 0,
    },
    label: {
      position: "top",
      style: {
        fill: "#000",
        opacity: 0.9,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `${v.toFixed(2)} B`,
      },
    },
    tooltip: false,
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10,
    },
  };

  const lineConfig = {
    data: chartDataPrice,
    xField: "date",
    yField: "price",
    height: 350,
    color: "#69c0ff",
    style: {
      stroke: "green",
      lineWidth: 1.6,
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `$${v.toFixed(2)}`,
      },
    },
    tooltip: "price",
    annotations: [
      {
        type: "text",
        x: latestDate,
        data: [
          latestDate,
          chartDataPrice?.find((entry) => entry?.date === latestDate)?.price ||
            100,
        ],
        style: {
          text: `${latestResults?.date_period} Results \n ${latestResults?.revenueChange}% Revenue Change`,
          connector: true,
          startMarker: true,
          startMarkerFill: "#2C3542",
          startMarkerFillOpacity: 0.95,
          wordWrap: true,
          wordWrapWidth: 164,
          dx: -80,
          dy: -40,
          textAlign: "center",
          fontSize: 12,
          fill: "#808080",
          background: {
            fill: "#f0f0f0",
            stroke: "#808080",
            padding: [4, 4, 4, 4],
            radius: 4,
          },
          lineWidth: 2,
        },
        tooltip: false,
      },

      {
        type: "text",
        x: previousDate,
        data: [
          previousDate,
          chartDataPrice?.find((entry) => entry?.date === previousDate)
            ?.price || 100,
        ],
        style: {
          text: `${previousResults?.date_period} Results \n ${previousResults?.revenueChange}% Revenue Change`,
          connector: true,
          startMarker: true,
          startMarkerFill: "#2C3542",
          startMarkerFillOpacity: 0.95,
          wordWrap: true,
          wordWrapWidth: 164,
          dx: -120,
          dy: -40,
          textAlign: "center",
          fontSize: 12,
          fill: "#808080",
          background: {
            fill: "#f0f0f0",
            stroke: "#808080",
            padding: [4, 4, 4, 4],
            radius: 4,
          },
          lineWidth: 2,
        },
        tooltip: false,
      },
    ],
  };

  const buttonStyle = {
    padding: "5px 5px",
    margin: "0 3px",
    fontSize: "13px",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#69c0ff",
    color: "#fff",
    border: "1px solid #69c0ff",
  };

  return (
    <div>
      <h2>Key Metrics for {symbols[0]}</h2>
      <button
        onClick={() => setMetric("revenue")}
        style={metric === "revenue" ? activeButtonStyle : buttonStyle}
      >
        Revenues
      </button>
      <button
        onClick={() => setMetric("netIncome")}
        style={metric === "netIncome" ? activeButtonStyle : buttonStyle}
      >
        Net Income
      </button>

      <button
        onClick={() => setMetric("grossProfit")}
        style={metric === "grossProfit" ? activeButtonStyle : buttonStyle}
      >
        Gross Income
      </button>

      <button
        onClick={() => setMetric("eps")}
        style={metric === "eps" ? activeButtonStyle : buttonStyle}
      >
        EPS
      </button>
      <div style={{ width: "100%" }}>
        <BarChartPlot {...barConfig} />
      </div>
      <h2>Stock Price of {symbols[0]}</h2>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: "80%" }}>
          <LineChartPlot {...lineConfig} />
        </div>
      </div>

      {symbols?.length > 0 ? <NewsCarousel symbol={symbols[0]} /> : null}
    </div>
  );
};

export const CompanyChartsMultiple = ({ symbols }) => {
  const [chartData, setChartData] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [metric, setMetric] = useState("revenue");
  const [xAxisPeriods, setXAxisPeriods] = useState([]);

  const adjustYearBasedOnPeriod = (period, year) => {
    switch (period) {
      case "Q1":
        return year + 1;
      case "Q2":
      case "Q3":
      case "Q4":
      default:
        return year;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch revenue data for each symbol
        const revenuePromises = symbols.map((symbol) =>
          fetch(
            "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                text: [symbol],
                model_name: "ChartsDataIncome",
              }),
            }
          ).then((response) => response.json())
        );

        const revenueResults = await Promise.all(revenuePromises);

        let referenceSymbolData = null;

        const formattedRevenueData = revenueResults.flatMap((result, index) => {
          if (result && Array.isArray(result.summary)) {
            const symbol = symbols[index];
            const sortedData = result.summary
              .map((entry) => {
                const year = parseInt(entry.fillingDate.slice(0, 4), 10);
                const adjustedYear = adjustYearBasedOnPeriod(
                  entry.period,
                  year
                );
                return {
                  symbol: symbol,
                  date: entry.fillingDate,
                  date_period: entry.period,
                  calendarYear: entry.period + "`" + adjustedYear,
                  revenue: entry.revenue / 1000000000,
                  netIncome: entry.netIncome / 1000000000,
                  eps: entry.eps,
                  grossProfit: entry.grossProfit / 1000000000,
                };
              })
              .sort((a, b) => new Date(b.date) - new Date(a.date));
            if (!referenceSymbolData) {
              referenceSymbolData = sortedData.slice(0, 8).reverse();
              setXAxisPeriods(
                referenceSymbolData.map((item) => item.calendarYear)
              );
            }
            return sortedData.reverse();
          }
          return [];
        });

        setChartData(formattedRevenueData);

        // Fetch price data for each symbol
        const pricePromises = symbols.map((symbol) =>
          fetch(
            "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                text: [symbol],
                model_name: "ChartsDataPrice",
              }),
            }
          ).then((response) => response.json())
        );

        const priceResults = await Promise.all(pricePromises);

        const formattedPriceData = priceResults.flatMap((result, index) => {
          if (result && Array.isArray(result.summary)) {
            const symbol = symbols[index];
            return result.summary
              .map((entry) => ({
                symbol: symbol,
                date: entry.date,
                price: parseFloat(entry.close).toFixed(1),
              }))
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .slice(0, 300)
              .reverse();
          }
          return [];
        });

        setChartDataPrice(formattedPriceData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [symbols]);

  // console.log(chartData);

  const barConfig = {
    data: chartData.filter((d) => xAxisPeriods.includes(d.calendarYear)),
    xField: "calendarYear",
    yField: metric,
    height: 300, // Reduced height
    columnStyle: {
      stroke: "#B3E5FC", // Border color
      lineWidth: 2, // Border width
      fillOpacity: 0.3, // Lighter fill opacity
    },
    label: {
      textBaseline: "bottom",
      style: {
        fill: "#000",
        opacity: 0.5,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `${v.toFixed(2)} B`,
      },
    },
    tooltip: metric,
    style: {
      radiusTopLeft: 5,
      radiusTopRight: 5,
    },
    group: true,
    colorField: "symbol",
  };

  let latestDate = "2024-02-05";

  const lineConfig = {
    data: chartDataPrice,
    xField: "date",
    yField: "price",
    height: 300,
    style: {
      lineWidth: 1.5,
    },
    normalize: { basis: "first", groupBy: "color" },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      min: 0, // Start y-axis from 0
      label: {
        formatter: (v) => `$${v.toFixed(2)}`,
      },
    },
    tooltip: "price",
    colorField: "symbol",
  };

  const buttonStyle = {
    padding: "5px 5px",
    margin: "0 3px",
    fontSize: "13px",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#69c0ff",
    color: "#fff",
    border: "1px solid #69c0ff",
  };

  return (
    <div>
      <h2>Key Metric</h2>
      <button
        onClick={() => setMetric("revenue")}
        style={metric === "revenue" ? activeButtonStyle : buttonStyle}
      >
        Revenues
      </button>
      <button
        onClick={() => setMetric("netIncome")}
        style={metric === "netIncome" ? activeButtonStyle : buttonStyle}
      >
        Income
      </button>
      <button
        onClick={() => setMetric("grossProfit")}
        style={metric === "grossProfit" ? activeButtonStyle : buttonStyle}
      >
        Gross Income
      </button>

      <button
        onClick={() => setMetric("eps")}
        style={metric === "eps" ? activeButtonStyle : buttonStyle}
      >
        EPS
      </button>

      <BarChartPlot {...barConfig} />
      <h2>Stock Price</h2>
      <LineChartPlot {...lineConfig} />
      {/* {symbols?.length > 0 && <NewsCarousel symbols={symbols} />} */}
    </div>
  );
};

export const CompanyCharts = ({ symbols, subrouter }) => {
  if (subrouter === "single company analysis" && symbols.length === 1) {
    return <CompanyChartsSingle symbols={symbols} />;
  } else if (
    subrouter === "multiple companies analysis" &&
    symbols.length > 1
  ) {
    return <CompanyChartsMultiple symbols={symbols} />;
  } else if (subrouter === "fair valuation") {
    return <DCFFCompany symbol={symbols[0]} />;
  } else if (subrouter === "industry analysis") {
    return (
      <div style={{ color: "brown" }}>
        CHARTS-Work In progress for Industry analysis, Please check back in few
        weeks
      </div>
    );
  } else {
    return <div>No data available for the given query.</div>;
  }
};

export default CompanyCharts;

export const ChartsAndTables = ({ input_symbols, sql_columns }) => {
  console.log(input_symbols);
  const [chartData, setChartData] = useState([]);
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  // const [symbols, setSymbols] = useState('TSLA,MSFT,AMZN,INTC,A,AMD,NVDA,GOOG,NFLX');
  const [symbols, setSymbols] = useState(input_symbols?.split(","));

  // console.log(symbols)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(sql_columns);

  const requiredColumns = [
    "price",
    "beta",
    "revenue_change_qoq",
    "price_change_1year",
    "mktcap_billion",
    "revenue_annual_billion",
    "targetconsensus",
    "companyname",
  ];

  const columnDisplayNames = {
    price: "Price($)",
    beta: "Beta",
    companyname: "Company",
    revenue_change_qoq: "Qtr Rev Change(%)",
    price_change_1year: "52W Price Change(%)",
    mktcap_billion: "Market Cap($Bn.)",
    revenue_annual_billion: "Revenues($Bn.)",
    targetconsensus: "Analyst Targets($)",
  };

  const completeSqlColumns = () => {
    let columns = sql_columns
      ?.split(",")
      ?.filter((col) => col !== "targetconsensus");
    requiredColumns?.forEach((col) => {
      if (columns?.length < 7 && !columns?.includes(col)) {
        columns?.push(col);
      }
    });
    columns = columns?.slice(0, 7); // Ensure we only have 7 columns
    columns?.push("targetconsensus"); // Ensure 'targetconsensus' is the last column
    return columns;
  };

  const capitalize = (s) => {
    return s.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const metrics = [
    { title: "Revenue", color: "#b5d6d6", metric: "revenue" },
    { title: "Price", color: "#d4afb9", metric: "price" },
    { title: "Income", color: "#9cadce", metric: "netIncome" },
  ];

  const fetchData = async (symbols) => {
    // console.log(input_symbols);
    try {
      const sql_data = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ text: symbols, model_name: "SQLDataTable" }),
        }
      );

      if (!sql_data.ok) {
        throw new Error("Failed to fetch data");
      }

      let result_sql = await sql_data.json();
      if (result_sql) {
        result_sql = result_sql?.summary;
        let tableDataSQL = result_sql?.map((entry) => {
          let rowData = { image: entry.image };
          completeSqlColumns()?.forEach((col) => {
            rowData[col] =
              typeof entry[col] === "number"
                ? entry[col]?.toFixed(2)
                : entry[col];
          });
          return rowData;
        });

        setTableData2(tableDataSQL);
      }

      const response2 = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            text: symbols?.slice(0, 3),
            model_name: "ChartsDataIncome",
          }),
        }
      );

      if (!response2.ok) {
        throw new Error("Failed to fetch data");
      }

      let result2 = await response2.json();
      if (result2) {
        result2 = result2?.summary;
        let chartData = result2?.map((entry) => ({
          symbol: entry.symbol,
          date: entry.fillingDate,
          date_period: entry.period,
          calendarYear: entry.period + "`" + entry.calendarYear,
          revenue: entry.revenue / 1000000000,
          netIncome: entry.netIncome / 1000000000,
        }));
        setChartData(chartData);
      }

      const response_price = await fetch(
        "https://tqyorwyon1.execute-api.us-east-1.amazonaws.com/testingMistral",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            text: symbols,
            model_name: "ChartsDataPrice",
          }),
        }
      );

      if (!response_price.ok) {
        throw new Error("Failed to fetch data");
      }

      let result_price = await response_price.json();
      if (result_price) {
        result_price = result_price?.summary;
        let chartDataPrice = result_price?.map((entry) => ({
          symbol: entry.symbol,
          date: entry.date,
          price: entry.close,
        }));
        setChartDataPrice(chartDataPrice);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTableLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  useEffect(() => {
    fetchData(symbols);
  }, [symbols]);

  const renderColumns = () => {
    const columns = [
      {
        title: "",
        dataIndex: "image",
        key: "image",
        fixed: "left",
        width: 50,

        render: (image) => (
          <img
            src={image}
            alt="company-logo"
            style={{ width: 30, height: 30 }}
          />
        ),
      },
    ];

    const renderFilter = (column) => {
      if (!tableData2.length) return {};
      // console.log(column)
      const isNumericColumn = tableData2.every(
        (row) => typeof row[column] === "number"
      );
      // console.log(isNumericColumn)

      // console.log(typeof tableData2[0][column])
      if (isNumericColumn) {
        return {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Slider
                range
                min={0}
                max={Math.max(...tableData2.map((data) => data[column]))}
                onChange={(value) => setSelectedKeys(value)}
                value={
                  selectedKeys.length
                    ? selectedKeys
                    : [0, Math.max(...tableData2.map((data) => data[column]))]
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 0",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  style={{ marginRight: 8 }}
                >
                  Filter
                </Button>
                <Button onClick={() => clearFilters()}>Reset</Button>
              </div>
            </div>
          ),
          onFilter: (value, record) =>
            record[column] >= value[0] && record[column] <= value[1],
        };
      } else {
        return {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Select
                mode="multiple"
                showSearch
                style={{ width: 200 }}
                placeholder={`Select ${column}`}
                value={selectedKeys}
                onChange={(value) => setSelectedKeys(value)}
              >
                {Array.from(
                  new Set(tableData2.map((data) => data[column]))
                ).map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 0",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  style={{ marginRight: 8 }}
                >
                  Filter
                </Button>
                <Button onClick={() => clearFilters()}>Reset</Button>
              </div>
            </div>
          ),
          onFilter: (value, record) => value.includes(record[column]),
        };
      }
    };

    completeSqlColumns()?.forEach((column, index) => {
      columns.push({
        title: (
          <div
            style={{
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
              fontWeight: "bold",
              fontSize: "14px",
              color: "grey",
            }}
          >
            {columnDisplayNames[column] || capitalize(column)}
          </div>
        ),
        dataIndex: column,
        key: column,
        fixed: index === 0 ? "left" : false,

        width: "auto", // Allow width to adjust based on content

        sorter: (a, b) => (a[column] > b[column] ? 1 : -1),
        ...renderFilter(column),
      });
    });

    return columns;
  };

  const symbols_filter = [...new Set(chartData?.map((item) => item.symbol))];
  // console.log(chartDataPrice);

  const top3Symbols = symbols_filter?.slice(0, 3);

  if (isMobile) {
    return (
      <ProCard bordered title="Table for Data based on your query">
        <Table
          dataSource={tableData2}
          loading={tableLoading}
          pagination={{ pageSize: 6 }}
          size="small"
          scroll={{ x: 800 }} // Adjust based on your needs to enable horizontal scrolling
          bordered
          columns={renderColumns()}
        ></Table>
        <Divider />

        <div>
          {top3Symbols.map((symbol) => (
            <div key={symbol}>
              <ProCard title={symbol} extra="Revenue" headerBordered>
                <DemoTinyColumnProfit
                  data={chartData.filter((item) => item.symbol === symbol)}
                  color="#b5d6d6"
                  metric="revenue"
                  isMobile={isMobile}
                />{" "}
              </ProCard>

              <ProCard title={symbol} extra="Price" headerBordered>
                <AdyDemoTinyArea
                  data={chartDataPrice.filter((item) => item.symbol === symbol)}
                  color="#d4afb9"
                  isMobile={isMobile}
                />{" "}
              </ProCard>

              <ProCard title={symbol} extra="Income" headerBordered>
                <DemoTinyColumnProfit
                  data={chartData.filter((item) => item.symbol === symbol)}
                  color="#9cadce"
                  metric="netIncome"
                  isMobile={isMobile}
                />
              </ProCard>

              <Divider></Divider>
            </div>
          ))}
        </div>
      </ProCard>
    );
  } else {
    // Desktop layout
    return (
      <ProCard bordered title="Table for Data based on your query">
        <Table
          dataSource={tableData2}
          loading={tableLoading}
          pagination={{ pageSize: 6 }}
          size="small"
          scroll={{ x: 1200 }}
          bordered={false}
          columns={renderColumns()}
          style={{ margin: "0px", padding: "0px" }} // Minimal margins and padding
        ></Table>
        <div>
          <Company_Tiny_Charts
            input_symbols={top3Symbols}
            metrics={metrics}
            chartDataPrice={chartDataPrice}
            chartData={chartData}
            isAsMobile={isMobile}
          />
        </div>
      </ProCard>
    );
  }
};

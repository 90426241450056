import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MenuUnfoldOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Layout, Menu, Input, Button, message, Dropdown, Modal } from "antd";
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  ArrowUpOutlined,
  ReadOutlined,
  CloseCircleOutlined,
  PlusCircleFilled,
  DownCircleTwoTone,
  SmileOutlined,
  CompassOutlined,
} from "@ant-design/icons";
import "wired-elements";
import Blog from "./Footer";
import DiscoverPage from "./DiscoverPage";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faBlog,
  faInfoCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const { Header, Content, Sider } = Layout;
const stockbuzzLogo = "/Stockbuzz.png";

export const handleChatRename = async (
  user_value_cookie,
  activeThread,
  newName,
  setLoading,
  setActiveThread,
  message
) => {
  console.log(newName);
  setLoading(true);
  try {
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
    const requestData = {
      operation: "renameChat",
      username: user_value_cookie,
      chat_id: activeThread.id,
      chat_name: newName,
    };

    await axios.post(apiUrl, requestData);

    setActiveThread({ ...activeThread, name: newName });
    message.success("Chat renamed successfully");
  } catch (error) {
    message.error("Failed to rename chat");
    console.error("Error:", error);
  } finally {
    setLoading(false);
  }
};

export const handleDeleteClick = async (
  user_value_cookie,
  thread,
  threads,
  setLoading,
  setActiveThread,
  message
) => {
  setLoading(true);
  try {
    const apiUrl =
      "https://hb07tmq1xc.execute-api.us-east-1.amazonaws.com/test1";
    const requestData = {
      operation: "deleteChat",
      username: user_value_cookie,
      chat_id: thread.id,
    };

    await axios.post(apiUrl, requestData);

    // Find the index of the deleted thread
    const deletedThreadIndex = threads.findIndex((t) => t.id === thread.id);

    // Remove the deleted thread from the threads array
    const updatedThreads = [...threads];
    updatedThreads.splice(deletedThreadIndex, 1);

    // Set the first thread as the active thread if the active thread is the deleted one
    setActiveThread((prevActiveThread) =>
      prevActiveThread?.id === thread.id
        ? updatedThreads.length > 0
          ? updatedThreads[0]
          : null
        : prevActiveThread
    );

    message.success("Chat deleted successfully");
  } catch (error) {
    message.error("Failed to delete chat");
    console.error("Error:", error);
  } finally {
    setLoading(false);
  }
};

export const MenuComponent = ({
  handleMenuClick,
  handleInputChange,
  user_value_cookie,
  thread,
  newChatName,
  setLoading,
  setActiveThread,
  threads,
}) => {
  return (
    <Menu>
      <Menu.Item
        key="rename"
        icon={<EditOutlined />}
        style={{ fontSize: "small" }}
        onClick={handleMenuClick}
      >
        <Input
          defaultValue=""
          onChange={handleInputChange}
          onPressEnter={() =>
            handleChatRename(
              user_value_cookie,
              thread,
              newChatName,
              setLoading,
              setActiveThread,
              message
            )
          }
          onBlur={() =>
            handleChatRename(
              user_value_cookie,
              thread,
              newChatName,
              setLoading,
              setActiveThread,
              message
            )
          }
          autoFocus
          onClick={(e) => e.stopPropagation()}
        />
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        style={{ fontSize: "small" }}
        onClick={() =>
          handleDeleteClick(
            user_value_cookie,
            thread,
            threads,
            setLoading,
            setActiveThread,
            message
          )
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );
};

export const SideBarComponent = ({
  isMobile,
  siderVisible,
  setSiderVisible,
  navigate,
  activeButton,
  handleNewChatClick,
  handleThreadClick,
  handleMenuClick,
  handleInputChange,
  user_value_cookie,
  activeThread,
  newChatName,
  setLoading,
  setActiveThread,
  threads,
  currentUser, // Add this prop to check for current user
}) => {
  const handleThreadSelect = (thread) => {
    handleThreadClick(thread);
    if (isMobile) {
      console.log("Mobile mode: Closing sidebar after thread selection.");
      setSiderVisible(false); // Close the sidebar when a thread is selected in mobile mode
    }
  };

  const closeSidebar = () => {
    if (isMobile) {
      setSiderVisible(false);
    }
  };

  const handleBlogClick = () => {
    window.location.href = "/blogs";
  };

  const handleAboutUsClick = () => {
    window.location.href = "/AboutUs";
  };

  const handleMetricsClick = () => {
    window.location.href = "/Metrics";
  };

  const handleContactClick = () => {
    window.location.href = "/Contact";
  };

  const navigateTo = (path) => {
    navigate(path);
    closeSidebar();
  };

  return (
    <Sider
      width={isMobile ? "70%" : "20%"}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        background: "#f5f5f5",
        borderRight: "1px solid #f0f0f0", // Add border here
        left: 0,
        zIndex: 1000, // Make sure this is less than the Header to allow the toggle button to be accessible
        display: siderVisible ? "block" : "none", // Ensure visibility is controlled here
      }}
    >
      {isMobile && (
        <Button
          icon={
            <CloseCircleOutlined style={{ fontSize: "large", color: "grey" }} />
          }
          style={{
            position: "absolute",
            backgroundColor: "transparent",
            border: "none",
            top: 0,
            right: 0,
            zIndex: 5000, // Ensure the button is above other elements in the Sider
          }}
          // onClick={() => setSiderVisible(false)}
          onClick={closeSidebar} // Use closeSidebar function
        />
      )}{" "}
      {!isMobile && (
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "5%" }}
        >
          <img
            src={stockbuzzLogo}
            alt="Stockbuzz Logo"
            style={{ height: "60px" }}
          />

          <wired-button
            elevation="2"
            style={{
              width: "70%",
              margin: "2%",
              // marginLeft: "5%",
              fontWeight: "normal",
            }}
            onClick={() => navigate("/discover")} // Navigate to Discover page on click
          >
            Stockbuzz.ai
          </wired-button>
        </div>
      )}
      {!isMobile && (
        <div style={{ fontSize: "small", marginLeft: "30%" }}>
          {" "}
          Invest Smarter with AI{" "}
        </div>
      )}
      {!isMobile && (
        <Button
          style={{
            width: "70%",
            marginTop: "10%",
            marginBottom: "10%",
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "-10%",
            color: activeButton === "newChat" ? "#595959" : "black",
            fontWeight: activeButton === "newChat" ? "bold" : "normal",
            // fontSize: activeButton === "newChat" ? "medium" : "normal",
          }}
          onClick={handleNewChatClick}
        >
          {" "}
          {activeButton === "newChat" ? (
            <PlusCircleFilled />
          ) : (
            <PlusCircleOutlined />
          )}
          New Chat
        </Button>
      )}
      {!isMobile && (
        <Button
          style={{
            width: "70%",

            marginBottom: "10%",
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "-10%",
            color: activeButton === "newChat" ? "#595959" : "black",
            fontWeight: activeButton === "newChat" ? "bold" : "normal",
          }}
          onClick={() => {
            navigate("/discover");
            if (isMobile) {
              closeSidebar();
            }
          }}
        >
          <CompassOutlined />
          Discover
        </Button>
      )}
      <Menu
        mode="inline"
        style={{
          background: "#f5f5f5",
          maxHeight: "65vh", // Set maximum height to 60% of the viewport height
          overflow: "auto", // Enable scrolling when content exceeds the max height
        }}
        selectedKeys={[activeThread?.id]}
        // defaultOpenKeys={["library"]} // Ensures the 'Library' submenu is open by default
        defaultOpenKeys={isMobile ? [] : ["library"]}
      >
        {" "}
        {isMobile ? (
          <>
            <Menu.Item
              key="library"
              style={{
                fontWeight: "bold",
                cursor: "default",
                backgroundColor: "#ffffff",
                marginTop: "20%",
              }}
            >
              <ReadOutlined style={{ fontSize: "large", color: "grey" }} />
              <span style={{ marginLeft: "10px" }}>Library</span>
            </Menu.Item>
            {threads.map((thread) => (
              <Menu.Item
                key={thread.id}
                onClick={() => handleThreadSelect(thread)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: 1, overflow: "hidden" }}>
                    <span
                      style={{
                        marginRight: "1px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {thread.name}
                    </span>
                  </div>
                  <div>
                    <Dropdown
                      overlay={
                        <MenuComponent
                          handleMenuClick={handleMenuClick}
                          handleInputChange={handleInputChange}
                          user_value_cookie={user_value_cookie}
                          thread={thread}
                          newChatName={newChatName}
                          setLoading={setLoading}
                          setActiveThread={setActiveThread}
                          threads={threads}
                        />
                      }
                      trigger={["click"]}
                      style={{ zIndex: 5010 }}
                    >
                      <Button
                        type="link"
                        icon={<EllipsisOutlined />}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Dropdown>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </>
        ) : (
          <Menu.SubMenu
            key="library"
            style={{
              background: "#ffffff",
            }}
            title="Library"
            icon={<ReadOutlined style={{ fontSize: "large", color: "grey" }} />}
          >
            {threads.map((thread) => (
              <Menu.Item
                key={thread.id}
                onClick={() => handleThreadSelect(thread)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: 1, overflow: "hidden" }}>
                    <span
                      style={{
                        marginRight: "1px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {thread.name}
                    </span>
                  </div>
                  <div>
                    <Dropdown
                      overlay={
                        <MenuComponent
                          handleMenuClick={handleMenuClick}
                          handleInputChange={handleInputChange}
                          user_value_cookie={user_value_cookie}
                          thread={thread} // Pass the specific thread here
                          newChatName={newChatName}
                          setLoading={setLoading}
                          setActiveThread={setActiveThread}
                          threads={threads}
                        />
                      }
                      trigger={["click"]}
                      style={{ zIndex: 5010 }}
                    >
                      <Button
                        type="link"
                        icon={<EllipsisOutlined />}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Dropdown>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}
      </Menu>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          paddingBottom: "10px", // Optional padding for aesthetics
        }}
      >
        {isMobile && !currentUser && (
          <div style={{ marginBottom: "8%" }}>
            <Button
              style={{
                backgroundColor: "#559e83",
                color: "white",
                margin: "1%",
                width: "80%",
                marginBottom: "2%",
              }}
              onClick={() => navigateTo("/SignUp")} // Close the sidebar and navigate
            >
              Sign up
            </Button>
            <Button
              style={{ margin: "1%", width: "80%" }}
              onClick={() => navigateTo("/Login")} // Close the sidebar and navigate
            >
              Log in
            </Button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "grey",
              fontSize: "small",
              margin: "3%",
              cursor: "pointer", // Change cursor to indicate clickable area
            }}
            onClick={handleBlogClick}
          >
            {" "}
            Blog
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "grey",
              fontSize: "small",
              margin: "2%",
              cursor: "pointer", // Change cursor to indicate clickable area
            }}
            onClick={handleMetricsClick}
          >
            Metrics
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "grey",
              fontSize: "small",
              margin: "3%",
              cursor: "pointer", // Change cursor to indicate clickable area
            }}
            onClick={handleAboutUsClick}
          >
            AboutUs
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "grey",
              fontSize: "small",
              margin: "3%",
              cursor: "pointer", // Change cursor to indicate clickable area
            }}
            onClick={handleContactClick}
          >
            Contact
            {/* <FontAwesomeIcon icon={faEnvelope} /> */}
          </button>
        </div>
      </div>
    </Sider>
  );
};

export const HeaderComponent = ({
  isMobile,
  toggleSider,
  navigate,
  handleNewChatClick,
}) => {
  if (!isMobile) return null;

  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "whitesmoke",
        zIndex: 1000,
        position: "fixed",
        top: 0,
        width: "80vw",
      }}
    >
      <Button
        icon={<MenuUnfoldOutlined />}
        onClick={toggleSider}
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "grey",
        }}
      />
      <Button
        style={{ backgroundColor: "transparent", border: "none" }}
        onClick={() => navigate("/discover")}
      >
        Stockbuzz.ai
      </Button>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={handleNewChatClick}
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "grey",
        }}
      />
    </Header>
  );
};

export const BottomLine = ({ isMobile }) => {
  const desktopStyle = {
    position: "fixed",
    bottom: 0,
    left: "45%",
    transform: "translateX(-35%)",
    color: "grey",
    backgroundColor: "white",
    padding: "1%",
    paddingTop: "1%",
    zIndex: 99, // Ensure it appears above other elements
    width: "100%", // Optional: Set width to 100% if you want it to span the entire bottom
    textAlign: "center", // Optional: Center text

    fontSize: "small",
  };
  const mobileStyle = {
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    color: "grey",
    backgroundColor: "white",
    padding: "1%",
    paddingTop: "10%",
    zIndex: 99,
    width: "100%",
    textAlign: "center",
    fontSize: "small",
  };
  return (
    <div style={isMobile ? mobileStyle : desktopStyle}>
      Unlimited free questions for humans <SmileOutlined />
    </div>
  );
};

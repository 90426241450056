import React from "react";
import { Button } from "antd";
import {
  ArrowUpOutlined,
  DotChartOutlined,
  RiseOutlined,
  DollarOutlined,
  BulbOutlined,
  AppleOutlined,
} from "@ant-design/icons";
import "./App.css";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export const SamplePrompts = ({
  threads,
  newChat,
  setNewMessage,
  isMobile,
  isVisible,
}) => {
  const prompts = isMobile
    ? [
        {
          message:
            "Top 5 companies with market cap less than 100Bn and involved in Genetic research",
          label:
            "Top 5 companies with market cap less than 100Bn and involved in Genetic research",
          icon: <DollarOutlined style={{ color: "#fd6f7f" }} />,
        },
        {
          message: "What is Cigar-Butt Investing",
          label: "What is Cigar-Butt Investing",
          icon: <BulbOutlined style={{ color: "#fbc396" }} />,
        },
      ]
    : [
        // { message: "Tell me about Amazon", label: "Tell me about Amazon" },
        {
          message: "What does Warren Buffet think about Bitcoin?",
          label: "What does Warren Buffet think about Bitcoin?",
          icon: <DollarOutlined style={{ color: "#fd6f7f" }} />,
        },

        {
          message:
            "Top 5 Genetic research companies with market cap less than 100Bn",
          label: "Top 5 Genetic research companies with market cap less than 100Bn",
          icon: <DollarOutlined style={{ color: "#fd6f7f" }} />,
        },
        {
          message: "What is Cigar-Butt Investing",
          label: "What is Cigar-Butt Investing",
          icon: <BulbOutlined style={{ color: "#fbc396" }} />,
        },

        {
          message:
            "Top Companies with more than 20% price increase in last month",
          label: "Companies with more than 20% price increase in last month",
          icon: <RiseOutlined style={{ color: "#78dfb9" }} />,
        },
      ];

  if (!isVisible) return null; // Don't render anything if not visible

  return (
    <div style={{ background: "#fff" }}>
      {
        <>
          <div
            style={{
              position: "fixed",
              top: isMobile ? "28%" : "28%",
              left: isMobile ? "50%" : "60%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: "999",
            }}
          >
            <div
              style={{
                marginBottom: "5%",
                color: "black",
                fontSize: isMobile ? "medium" : "x-large",
                fontWeight: "normal",
              }}
            >
              Seeking Stock Market Insights?
            </div>
          </div>

          <div
            style={{
              position: "fixed",
              // bottom: isMobile ? "15%" : "15%",
              width: isMobile ? "100%" : "calc(100% - 340px)",
              top: isMobile ? "40%" : "40%",
              // bottom: "15%",
              // width: "calc(100% - 300px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                gap: "10px",
                textAlign: "center",
                width: isMobile ? "80%" : "80%",
                margin: "0 auto", // Center the container
                marginLeft: isMobile ? "5%" : "10%",
              }}
            >
              {prompts.map((prompt) => (
                <Button
                  key={prompt.label}
                  onClick={() => setNewMessage(prompt.message)}
                  className="custom-button-prompt"
                >
                  {prompt.icon}
                  {prompt.label}
                  <ArrowUpOutlined className="hover-icon" />
                </Button>
              ))}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export const generateRandomId = () => {
  return `chat${Math.floor(Math.random() * 1000)}`;
};

export const manageCookie = () => {
  let cookie_variable = "val1";
  let name = Cookies.get(cookie_variable);

  if (name) {
    // console.log(`Cookie found`);
  } else {
    name = uuidv4();
    Cookies.set(cookie_variable, name, { expires: 365 });
  }
  return name;
};

export default SamplePrompts;

import React from "react";
import Card from "antd/lib/card";
import { Divider } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";

export const Blog = () => {
  return (
    <div style={{ marginLeft: "15%", marginRight: "15%", marginTop: "5%" }}>
      {/* Add your blog content here */}
      <p>
        <Card title="Coming Soon, Stay Tuned :)"></Card>
        <Divider />
      </p>
    </div>
  );
};

export const Metrics = () => {
  return (
    // <div style={{ marginLeft: "15%", marginRight: "15%", marginTop: "5%" }}>
    //   <a
    //     href="https://s3.amazonaws.com/www.stockbuzz.ai/Stockbuzz.ai+S3.xlsx"
    //     target="_blank"
    //     rel="noopener noreferrer"
    //   >
    //     Metrics
    //   </a>
    // </div>
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "10%",
      }}
    >
      <iframe
        src="https://docs.google.com/spreadsheets/d/1ooKhpL35KDI4kryrPOKM7MCFLDE6yUfkjUPAdXLeDQs/preview"
        width="100%"
        height="600"
        frameBorder="0"
        scrolling="no"
        title="Metrics"
      ></iframe>
    </div>
  );
};

export const AboutUs = () => {
  return (
    <div>
      <Card
        cover={
          <img
            alt="Aditi"
            src="https://s3.amazonaws.com/www.stockbuzz.ai/Aditi-1.JPG"
            // src="Aditi-1.JPG"
          />
        }
        style={{
          marginLeft: "20%",
          marginRight: "20%",
          marginTop: "5%",
          marginBottom: "10%",
        }}
      >
        <strong>Welcome to StockBuzz.ai!</strong>
        <p>
          This journey began in June 2024 amidst the bustling world of finance
          and technology. As a passionate retail investor, I have always been
          fascinated by the power big fund houses have, and I want to cover the
          gap by providing the same power in the hands of all retail investors.
        </p>
        <p>
          Our mission is to provide best tech tools possible to our customers to
          help them stay above the curve and be able to make confident investing
          decisions.{" "}
        </p>
        <p>
          Behind this platform are several AI models trained on US stock market
          public information of the last 5 years, 100+ popular investing books,
          SEC filings and 500+ key financial metrics.{" "}
        </p>
        <p>
          One of the key features is unlimited chat for humans. There is also a
          library on 'Discover' page to get you started. You can ask questions
          to structured database in natural language.
        </p>
        <p>
          Oh, and I almost forgot - all of this is free! That's right, I am
          making no money out of this, just seeing new users on my website is
          enough for me right now :)
        </p>
        <p>
          Please don't hesitate to reach out if you have any questions or
          feedback. I would love to hear from you and I am just an email away!
        </p>
        <p>
          {" "}
          Hope you enjoy using our platform and find it valuable in your
          investment journey.{" "}
        </p>
        <p>
          Happy Investing!
          <br />
          Aditi, Founder of StockBuzz.ai
          <br />
          Email: aditi@stockbuzz.ai
          <br /> <br />
          <br />
          Made with <HeartFilled style={{ color: "red" }} /> from Seattle
        </p>
      </Card>
    </div>
  );
};

export const Contact = () => {
  return (
    <div style={{ marginTop: "10%", marginLeft: "5%", marginRight: "5%" }}>
      <iframe
        src="https://sdswnxl5wn9.typeform.com/to/cVPCmbjn"
        style={{
          width: "100%",
          height: "500px",
          border: "none",
        }}
        allowFullScreen
      ></iframe>
      <div style={{ marginTop: "2%" }}>
        Drop an email to <strong>aditi@stockbuzz.ai</strong>
      </div>
    </div>
  );
};
